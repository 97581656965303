import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { getPlanSaleByType } from "../../utils/api";

import { DefaultContainer } from "../../components/ComponentStyle";
import PlanCard from "../../components/PlanCard";

import { Col, Row, Segmented, Dropdown, Button, Empty, Pagination, Spin } from "antd";
import { SignalFilled } from "@ant-design/icons";

function HomePage() {

    const { t } = useTranslation();

    const [planSegmente, setPlanSegmente] = useState('basic');
    const [planSales, setPlanSales] = useState([]);

    const [isMobileView, setIsMobileView] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const isSubscribed = useRef(false);

    const items = [
        {
            value: 'basic',
            key: 'basic',
            label: t('home.plan.basic_label')
        },{
            value: 'travel',
            key: 'travel',
            label: t('home.plan.travel_label')
        },{
            value: 'work',
            key: 'work',
            label: t('home.plan.work_label')
        },
    ]

    const handleChangeSegment = async(value) => {
        setPlanSegmente(value);
        const data = {
            type: value,
            offset: 0,
            limit: 8
        }
        await handleGetPlans(data);
    };
    const handleChangePagination = async(page, pageSize) => {
        const data = {
            type: planSegmente,
            offset: (page - 1),
            limit: pageSize
        }
        await handleGetPlans(data);
    }
    const handleGetPlans = async (value) => {
        setIsLoading(true);
        try {
            const res = await getPlanSaleByType(value);
            const result = res.data;
            if (res.status === 200) {
                setPlanSales(result || []);
            }
        }catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const renderContent = () => {
        switch (planSegmente) {
            case 'basic':
                return (
                    <Col span={24} className="title-description">
                        <h1 style={{ marginTop: '0px' }}>{t('home.plan.basic_label')}</h1>
                        <p>{t('home.plan.basic_description')}</p>
                    </Col>
                );
            case 'travel':
                return (
                    <Col span={24} className="title-description">
                        <h1 style={{ marginTop: '0px' }}>{t('home.plan.travel_label')}</h1>
                        <p>{t('home.plan.travel_description')}</p>
                    </Col>
                );
            case 'work':
                return (
                    <Col span={24} className="title-description">
                        <h1 style={{ marginTop: '0px' }}>{t('home.plan.work_label')}</h1>
                        <p>{t('home.plan.work_description')}</p>
                    </Col>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        const updateMobileView = () => {
            if (window.innerWidth < 768) {
                setIsMobileView(true);
            }
        };
    
        updateMobileView();
    }, []);

    useEffect(() => {
        if (!isSubscribed.current) {
            const data = {
                type: 'basic',
                offset: 0,
                limit: 8
            }
            handleGetPlans(data);
            isSubscribed.current = true;
        }
    }, []);

    return (
        <DefaultContainer>
            <Spin spinning={isLoading} fullscreen />
            <div className="container-index">
                { isMobileView ? (
                        <div style={{ marginBottom: 18, textAlign: 'right', width: '100%' }}>
                            <Dropdown
                                menu={{
                                    items,
                                    onClick: ({ key }) => { handleChangeSegment(key) }
                                }}
                                placement="bottomLeft"
                                arrow
                            >
                                <Button className="button-dropdown-plans" size="large">
                                    <SignalFilled />
                                </Button>
                            </Dropdown>
                        </div>
                    ) : (
                        <Segmented
                            value={planSegmente}
                            style={{ marginBottom: 36 }}
                            onChange={handleChangeSegment}
                            options={items}
                            size="large"
                        />
                    ) 
                      
                }
                <Row gutter={[16, 16]} className="container-content">
                    {renderContent()}
                    <Col span={24} style={{ marginTop: '20px' }}>
                        {
                            planSales['total'] > 0 ? (
                                <PlanCard propPlans={planSales['data']} />
                            ) : (
                                <Empty />
                            )
                        }
                    </Col>
                    <Col span={24} style={{ marginTop: '20px' }}>
                        <Pagination 
                            align="center" 
                            defaultCurrent={1} 
                            pageSize={8}
                            total={planSales['total']}
                            hideOnSinglePage={true}
                            onChange={handleChangePagination}
                        />
                    </Col>
                </Row>
            </div>
        </DefaultContainer>
    );
}

export default HomePage;