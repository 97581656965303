import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { WrapperInstruction } from '../components/ComponentStyle';
import { Row, Col, Alert, Segmented } from 'antd';

const InstructionEsim = (warning) => {

    const { t } = useTranslation();

    const [planSegmente, setPlanSegmente] = React.useState('1');
    const [warningEnabled, setWarningEnabled] = React.useState(false);

    const options = [
        {
            value: '1',
            label: t('home.guide_installation.ios_installation'),
        },
        {
            value: '2',
            label: t('home.guide_installation.android_installation'),
        },
    ];
    const suggessions = [
        t('home.guide_installation.suggessions_1'),
        t('home.guide_installation.suggessions_2'),
        t('home.guide_installation.suggessions_3'),
        t('home.guide_installation.suggessions_4'),
        t('home.guide_installation.suggessions_5')
       
    ];
    const guidesIOS = [
        {
            title: '1',
            content: t('home.guide_installation.guide_ios_description_1'),
            photo: "/images/guide-ios/1.png.webp",

        },{
            title: '2',
            content: t('home.guide_installation.guide_ios_description_2'),
            photo: "/images/guide-ios/2.webp",
        },{
            title: '3',
            content: t('home.guide_installation.guide_ios_description_3'),
            photo: "/images/guide-ios/3.webp",
        },{
            title: '4',
            content: t('home.guide_installation.guide_ios_description_4'),
            photo: "/images/guide-ios/4.webp",
        },{
            title: '5',
            content: t('home.guide_installation.guide_ios_description_5'),
            photo: "/images/guide-ios/5.webp",
        },{
            title: '6',
            content: t('home.guide_installation.guide_ios_description_6'),
            photo: "/images/guide-ios/6.webp",
        },{
            title: '7',
            content: t('home.guide_installation.guide_ios_description_7'),
            photo: "/images/guide-ios/7.webp",
        },{
            title: '8',
            content: t('home.guide_installation.guide_ios_description_8'),
            photo: "/images/guide-ios/8.webp",
        },{
            title: '9',
            content: t('home.guide_installation.guide_ios_description_9'),
            photo: "/images/guide-ios/9.webp",
        },{
            title: '10',
            content: t('home.guide_installation.guide_ios_description_10'),
            photo: "/images/guide-ios/10.webp",
        },{
            title: '11',
            content: t('home.guide_installation.guide_ios_description_11'),
            photo: "/images/guide-ios/11.webp",
        },{
            title: '12',
            content: t('home.guide_installation.guide_ios_description_12'),
            photo: "/images/guide-ios/12.webp",
        },{
            title: t('home.guide_installation.title_ios_before_fly'),
            describe: t('home.guide_installation.description_ios_before_fly'),
            topic: t('home.guide_installation.topic_ios_before_fly'),
            content: [
                t('home.guide_installation.content_ios_1'),
                t('home.guide_installation.content_ios_2'),
                t('home.guide_installation.content_ios_3'),
                t('home.guide_installation.content_ios_4'),
            ],
            finally: t('home.guide_installation.final_description'),
        }
    ];
    const guidesAndroid = [
        {
            title: '1',
            content: [
                'Go to “Settings” > “Connections”'
            ],
            photo: "/images/guide-android/a1.webp",

        },{
            title: '2',
            content: [
                'Click “SIM manager”'
            ],
            photo: "/images/guide-android/a2.webp",
        },{
            title: '3',
            content: [
                'Click “Add eSIM”'
            ],
            photo: "/images/guide-android/a3.webp",
        },{
            title: '4',
            content: [
                'Click “Scan QR code from service provider”'
            ],
            photo: "/images/guide-android/a4.webp",
        },{
            title: '5',
            content: [
                'Scan your code:',
                '*Note that the QR code must be presention another screen or device' 
            ],
            photo: "/images/guide-android/a5.webp",
        },{
            title: '6',
            content: [
                'Click “Add” :',
                '* Note that this step might take a few moments'
            ],
            photo: "/images/guide-android/a6.webp",
        },{
            title: '7',
            content: [
                'Congratulations!',
                'Your eSIM was added to your “SIM manager”.',
                'Note that under Preferred SIMs:',
                '“Calls” should be set on your SIM.',
                '“Messages” should be set on your SIM.',
                '“Mobile data” should be set on your eSIM.'
            ],
            photo: "/images/guide-android/a7.webp",
        },{
            title: t('home.guide_installation.title_ios_before_fly'),
            describe: t('home.guide_installation.description_ios_before_fly'),
            topic: t('home.guide_installation.topic_ios_before_fly'),
            content: [
                t('home.guide_installation.content_and_1'),
                t('home.guide_installation.content_and_2'),
                t('home.guide_installation.content_and_3'),
                t('home.guide_installation.content_and_4'),
            ],
            finally: t('home.guide_installation.final_description'),
        }
    ];

    const handleChangeSegment = async(value) => {
        setPlanSegmente(value);
    };

    useEffect(() => {
        if(warning.warning) {
            setWarningEnabled(true);
        }
    },[])

    return (
        <WrapperInstruction>
            <Row className='instruction-container'>
                {
                    warningEnabled && (
                        <Col span={24}>
                            <Alert message={<p style={{margin: '0px'}}><strong>{t('order_detail.warning')}</strong> {t('eSIMs can only be installed once. If you remove the eSIM from your device, you cannot install it again.')}</p>} type="warning" />
                        </Col>
                    )
                }
                <Col span={24} className='segment-wrapper'>
                    <Segmented
                        value={planSegmente}
                        onChange={handleChangeSegment}
                        options={options}
                        size="large"
                        block
                    />
                </Col>
                <Col span={24} className='instruction-wrapper'>
                    <h1>{t('How to install?')}</h1>
                    <ul>
                        {suggessions.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>

                    <Row className='guides-wrapper' gutter={[36, 16]}>
                        {
                            planSegmente === '1' && guidesIOS.map((item, index) => 
                                (
                                    <Col span={24} key={index} >
                                        <Row>
                                            {
                                                index > 11 ? (
                                                    <Col span={24} className='guide-solution'>
                                                        <h1>{item.title}</h1>
                                                        <p>{item.describe}</p>
                                                        <p>{item.topic}</p>
                                                        <ol>
                                                            {item.content.map((content, index) => (
                                                                <li key={index}>{content}</li>
                                                            ))}
                                                        </ol>
                                                        <p style={{marginBottom: '0px'}}>{item.finally}</p>
                                                    </Col>  
                                                ) : (
                                                    <Col span={24} className='guide-item'>
                                                        <img src={item.photo} alt="guide" className='guide-image'/>
                                                        <div className='guide-content'>
                                                            <h1>{item.title}</h1>
                                                            <p>{item.content}</p>
                                                        </div>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </Col>
                                )
                            )
                        }
                        {
                            planSegmente === '2' && guidesAndroid.map((item, index) => (
                                <Col span={24} key={index} >
                                    <Row>
                                        {
                                            index > 6 ? (
                                                <Col span={24} className='guide-solution'>
                                                    <h1>{item.title}</h1>
                                                    <p>{item.describe}</p>
                                                    <p>{item.topic}</p>
                                                    <ol>
                                                        {item.content.map((content, index) => (
                                                            <li key={index}>{content}</li>
                                                        ))}
                                                    </ol>
                                                    <p style={{marginBottom: '0px'}}>{item.finally}</p>
                                                </Col>  
                                            ) : (
                                                <Col span={24} key={index} className='guide-item'>
                                                    <img src={item.photo} alt="guide" className='guide-image'/>
                                                    <div className='guide-content'>
                                                        <h1>{item.title}</h1>
                                                        {
                                                            item.content.map((content, index) => (
                                                                <p key={index}>{content}</p>
                                                        ))}
                                                    </div>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Col>
                            ))
                        }
                    </Row>
                </Col>
            </Row>
        </WrapperInstruction>
    );
}

export default InstructionEsim;
