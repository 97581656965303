import React from 'react';
import { useTranslation } from "react-i18next";

import { Row, Col, Divider } from 'antd';

import { WrapperDeviceSupport } from '../../components/ComponentStyle';


const DeviceSupport = () => {

    const { t } = useTranslation();

    const devices = [
        {
            category: 'Apple',
            models: [
                'iPhone 16',
                '16 Plus',
                '16 Pro', 
                '16 Pro Max',
                'iPhone 15',
                '15 Plus',
                '15 Pro', 
                '15 Pro Max',
                'iPhone 14', 
                '14 Plus', 
                '14 Pro', 
                '14 Pro Max',
                'iPhone 13',
                '13 mini', 
                '13 Pro', 
                '13 Pro Max',
                'iPhone 12, 12 mini, 12 Pro, 12 Pro Max',
                'iPhone SE (2nd and 3rd generation)',
                'iPad Pro (3rd generation and later)',
            ]
        },
        {
            category: 'Samsung',
            models: [
                'Galaxy A55',
                'Galaxy A54',
                'Galaxy A53',
                'Galaxy S24, S24+, S24 Ultra',
                'Galaxy S23, S23+, S23 Ultra, S23 FE',
                'Galaxy S22 5G, S22+ 5G, S22 Ultra 5G',
                'Galaxy S21 5G, S21+ 5G, S21 Ultra 5G (US versions of S21 are not compatible with eSIM)',
                'Galaxy S20, S20 5G, S20+, S20+ 5G, S20 Ultra, S20 Ultra 5G (US versions of S20 and S20 FE 4G/5G are not compatible with eSIM)',
                'Galaxy Note20, Note20 5G, Note20 Ultra 5G (US and Hong Kong versions of Note 20 Ultra are not compatible with eSIM)',
                'Galaxy Xcover7',
                'Galaxy Fold',
                'Galaxy Z Fold4',
                'Galaxy Z Fold3 5G',
                'Galaxy Z Fold2 5G',
                'Galaxy Z Flip4',
                'Galaxy Z Flip3 5G',
                'Galaxy Z Flip and Z Flip 5G (US versions of Z Flip 5G are not compatible with eSIM)'
            ]
        },
        {
            category: 'Google Pixel',
            models: [
                'Pixel 7, 7 Pro',
                'Pixel 6, 6 Pro',
                'Pixel 5, 5a 5G',
                'Pixel 4, 4a, 4 XL, 4a 5G',
                'Pixel 3, 3a*, 3 XL, 3a XL'
            ]
        },
        {
            category: 'Xiaomi',
            models: [
                'Xiaomi 13, 13 Lite, 13 Pro',
                'Xiaomi 12T Pro',
            ]
        },
        {
            category: 'Huawei',
            models: [
                'Huawei P40 and P40 Pro* (not the P40 Pro +)',
                'Huawei Mate40 Pro',
            ]
        },
        {
            category: 'Huawei',
            models: [
                'Huawei P40 and P40 Pro* (not the P40 Pro +)',
                'Huawei Mate40 Pro',
            ]
        },
        {
            category: 'Sony',
            models: [
                'Sony Xperia 10 III Lite',
                'Sony Xperia 10 IV',
                'Sony Xperia 5 IV',
                'Sony Xperia 1 IV',
            ]
        },
        {
            category: 'Motorola',
            models: [
                'Motorola Razr 2019 and 5G',
                'Motorola Edge (2023), Edge (2022)',
                'Motorola Edge 40, 40 Pro',
                'Motorola Moto G (2023)',
            ]
        },
        {
            category: 'Other Brands',
            models: [
                'Huawei P40, P40 Pro',
                'Motorola Razr 5G',
                'Sony Xperia 1 IV',
                'Microsoft Surface Duo, Duo 2',
                'OPPO Find X3, X3 Pro, X5, X5 Pro',
                'OPPO Find N2 Flip',
                'OPPO Reno A',
                'Honor Magic 4 Pro, Magic5 Pro',
                'HAMMER Explorer PRO',
                'HAMMER Blade 3, Blade 5G',
                'myPhone NOW eSIM',
                'Rakuten Big, Big S, Mini, Hand',
                'SHARP Aquos Sense4 Lite, R7',
                'Fairphone 4',
                'DOOGEE V30',
                'Gemini PDA 4G+Wi-Fi',
                'Nuu Mobile X5',
                'OnePlus 12, 11',
                'Nokia XR21, X30, G60'
            ]
        }
    ];

    return (
        <WrapperDeviceSupport>
            <Row className='device-support-container'>
                <h1>{t('device_support.device_support')}</h1>
                {
                    devices.map((device, index) => (
                        <Col span={24} key={index} className='device-item'>
                            <h1>{device.category}</h1>
                            <Divider style={{margin: '18px 0px'}}/>
                            <Row>
                                <Col span={24} className='model-container'> 
                                    {
                                        device.models.map((model, index) => (
                                            <p key={index} className='model'>{model}</p>
                                        ))
                                    }
                                </Col>
                            </Row>
                        </Col>
                    ))
                }
            </Row>
        </WrapperDeviceSupport>
    );
};

export default DeviceSupport;