import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, Col, Dropdown, Row, Drawer, Input, QRCode, Spin, notification } from "antd";
import { RightOutlined, QrcodeOutlined, GlobalOutlined, MenuFoldOutlined, DownOutlined, UpOutlined, PlayCircleOutlined, ReadOutlined, CopyOutlined } from "@ant-design/icons";

import { HeaderWrapper } from "../ComponentStyle";
import InstructionEsim from "../InstructionEsim";

import { getEsimQr } from "../../utils/api";


function doDownload(url, fileName) {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

const Header = () => {

    const { t, i18n } = useTranslation();

    const [language, setLanguage] = useState('en');
    const [isOpen, setIsOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);
    const [isQrGenerated, setIsQrGenerated] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isLanguageChanged, setIsLanguageChanged] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [isInstructionOpen, setIsInstructionOpen] = useState(false);

    const [transactionNumber, setTransactionNumber] = useState('');
    const [paymentId, setPaymentId] = useState('');
    const [esimDetails, setEsimDetails] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    
    const [api, contextHolder] = notification.useNotification();

    const isSubscribedRef = useRef(false);
    const isQrValidRef = useRef(false);
    const hasCountdownStartedRef = useRef(false);

    const navigate = useNavigate();

    const itemLanguage = [
        {
            key: 'kh',
            label: "ខ្មែរ",
        },{
            key: 'en',
            label: "English",
        }
    ];
    const formGetQr = [
        {
            name: 'tranx_number',
            label: t(`global.order_id`),
            type: 'input',
            placeholder: t(`global.enter_order_id`),
            span: 24,
            required: true
        },{
            name: 'payment_id',
            label: t(`global.payment_id`),
            type: 'input',
            placeholder: t('global.enter_payment_id'),
            span: 24,
            required: true
        },
    ];

    const openDrawer = () => setIsOpen(true);
    const closeDrawer = () => setIsOpen(false);
    const onClick = ({key}) => {
        setLanguage(key);
        i18n.changeLanguage(key);
        document.documentElement.lang = key;
    
        if (isMobileView) {
            setIsLanguageChanged(false);
        }
    };
    const toggleLanguageChange = () => {
        if (isQrGenerated) {
            setIsQrGenerated(false);
        }
    
        setIsLanguageChanged(prevState => !prevState);
    };
    const handleInputChange = (name, value) => {
        const handlers = {
            'tranx_number': setTransactionNumber,
            'payment_id': setPaymentId,
        };
    
        if (handlers[name]) {
            handlers[name](value);
        }
    };
    const navigateHome = () => {
        setIsQrGenerated(false);
        setIsLanguageChanged(false);
        navigate('/');
    };
    const handleSaveQRCode = () => {
        const qrCodeElement = document.getElementById('myqrcode')?.querySelector('svg');
    
        if (!qrCodeElement) {
            api.error({
                message: 'QR Code not found.',
                placement: 'topRight',
            });
            return;
        }
    
        const svgData = new XMLSerializer().serializeToString(qrCodeElement);
        const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
        const downloadUrl = URL.createObjectURL(blob);
    
        doDownload(downloadUrl, 'QRCode.svg');
    
        api.success({
            message: 'QR Code downloaded successfully!',
            placement: 'topRight',
        });
    };
    const clearInfo = () => {
        setTransactionNumber('');
        setPaymentId('');
        setEsimDetails({});
        setPaymentStatus(false);
        isQrValidRef.current = false;
    };
    const handleEsimInfo = () => {
        setIsQrGenerated(!isQrGenerated);
        setPaymentStatus(false);
        isQrValidRef.current = false;
        clearInfo(); 
    };
    const handleDrawerInstallGuide = () => {
        setIsInstructionOpen(!isInstructionOpen);
    }; 
    const handleCopyToClipboard = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(() => {
                showNotification(
                    'success',
                    t(`global.message.copied_to_clipboard`),
                    null
                )
            }).catch(err => {
                showNotification(
                    'error',
                    t(`global.message.error_copied_to_clipboard`),
                    null
                )
            });
        }else {
            showNotification(
                'error',
                t(`global.message.fouced_error_copied_to_clipboard`),
                null
            )
        }
    };
    const handleInstalleSIM = (value) => {
        if(!value) return;
        showNotification(
            'info',
            t(`Under development process!`),
            null
        )
    };


    const submitGetQr = async() => {
        setIsLoading(true)
        if( !transactionNumber || !paymentId ){
            setIsFormSubmitted(true);
            setIsLoading(false)
            return;
        }

        await handleGetEsimInfo();
        setIsLoading(false)
    };
    const handleGetEsimInfo = async () => {
        const data = {
            "order_id": transactionNumber,
            "unique_code": paymentId,
        };
        try {
            const response = await getEsimQr(data);
            if (response.status === 201) {
                const result = response.data?.data;
                processEsimResponse(result);
            }
        } catch (error) {
            handleEsimError(error);
        }
    };
    const processEsimResponse = (result) => {
        setEsimDetails(result);

        if(result?.payment == false){
            showNotification(
                "info",
                "Payment Required",
                "Please pay the remaining amount before requesting a QR code."
            );
            return;
        }

        setPaymentStatus(true);
        
        if (result?.qrCodeContent) {
            isQrValidRef.current = true;
        }else{
            if(!hasCountdownStartedRef.current){
                showNotification(
                    "info",
                    "Processing QRCode",
                    "Your payment has been successfully processed, please wait for the QR code to be generated."
                );
                startCountDown();
                hasCountdownStartedRef.current = true;
            }
        }
    };
    const handleEsimError = (error) => {
        if (error.response?.status === 404 && error.response?.data?.message === "Not Found!") {
            isQrValidRef.current = false;
            showNotification(
                "error",
                "Not Found!",
                "Please check your information and try again."
            );
        } else {
            console.error("Unexpected error:", error);
        }
    };
    const startCountDown = async (repeatCount = 6) => {
        let secondsToGo = 10;

        const timer = setInterval(async () => {
            secondsToGo -= 1;
    
            if (secondsToGo <= 0) {
                clearInterval(timer);
    
                if (repeatCount > 1) {
                    await handleGetEsimInfo();

                    if (!isQrValidRef.current) {
                        startCountDown(repeatCount - 1);
                    }
                } else if (!isQrValidRef.current) {
                    clearInfo();
                    showNotification(
                        "info",
                        "Sorry for the inconvenience",
                        "Please try again later. if the problem persists, please contact us."
                    );
                }
            }
        }, 1000);

    };
    const showNotification = (type, message, description) => {
        api[type]({
            message,
            description,
            placement: "topRight",
            duration: type == "info" ? 3 : type == "success" ? 1.5 : 5,
            showProgress: true,
            pauseOnHover: true,
            className: "custom-notification",
            getContainer: () => document.body,
        });
    };

    useEffect(() => {
        const updateMobileView = () => {
            if (window.innerWidth < 768) {
                setIsMobileView(true);
            }
        };
    
        updateMobileView();
    }, []);
    
    useEffect(() => {
        if (!isSubscribedRef.current) {
            const storedLanguage = localStorage.getItem('i18nextLng');
            const defaultLanguage = 'kh';
            const languageKey = storedLanguage || defaultLanguage;
    
            onClick({ key: languageKey });
            isSubscribedRef.current = true;
        }
    }, []);

    return (
        <HeaderWrapper>
            {contextHolder}
            <Spin spinning={isLoading} fullscreen />
            <Row className="header-container" id="header-style-custom">
                <Col span={12} className="logo item-start" onClick={() => navigateHome()}>
                    <img src="/images/esim-logo-rectangle-fit-tr.png" alt="logo-esim" />
                </Col>
                {
                    isMobileView ? (
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" onClick={openDrawer} className="button-menu-mobile">
                                <MenuFoldOutlined />
                            </Button>
                        </Col>
                    ) : (
                        <Col span={12} className="menu-screen-pc item-end">
                            <Button className="button-menu" onClick={handleEsimInfo}>
                                <QrcodeOutlined /> {t('home.header.get_your_esim')}
                            </Button>
                            <Button className="button-menu" onClick={handleDrawerInstallGuide}>
                                <ReadOutlined /> {t('home.header.installation_guide')}
                            </Button>
                            <Button className="button-menu" onClick={() => window.open('https://www.youtube.com/watch?v=yikN-UgysnU&pp=ygUQYWJhIHBheW1lbnQgYmlsbA%3D%3D', '_blank')}>
                                <PlayCircleOutlined /> {t('home.header.how_to_do_payment')}
                            </Button>
                            <Drawer
                                title={t('home.header.get_your_esim')}
                                placement="right"
                                className="drawer-esim"
                                onClose={handleEsimInfo}
                                open={isQrGenerated}
                                getContainer={() => document.getElementById('header-style-custom')}
                                height="100%"
                                width={450}
                            >
                                {isQrGenerated && (
                                    <div className="wrapper-get-plan">
                                        {paymentStatus ? (
                                            isQrValidRef.current ? (
                                                <Row className="content-qr">
                                                    <Col span={24} >
                                                        <Row className="qr-box">
                                                            <Col span={24}>
                                                                <h1> 1: {t('home.header.qr_install')}</h1>
                                                                <p>{t('home.header.description_qr_install')}</p>
                                                            </Col>
                                                            <Col span={24} id="myqrcode">
                                                                <QRCode
                                                                    type="svg"
                                                                    errorLevel="H"
                                                                    value={esimDetails?.qrCodeContent}
                                                                    icon="/favicon.ico"
                                                                    size={250}
                                                                />
                                                                <Button className="button-save" onClick={handleSaveQRCode}>
                                                                    {t('global.button.save_qr')}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <Row className="manual-box">
                                                            <Col span={24}>
                                                                <h1>2: {t('home.header.data_manual_install')}</h1>
                                                                <p>{t('home.header.description_manual_install')}</p>
                                                            </Col>
                                                            <Col span={24} className="content-manual">
                                                                <Row gutter={[16, 16]}>
                                                                    <Col span={24} className="default-col">
                                                                        <div className="title-manual-item">
                                                                            <h2>{t('home.header.sm_dp')}</h2>
                                                                            <Button className="button-copy" icon={<CopyOutlined />} size="small" onClick={() => handleCopyToClipboard(esimDetails?.qrCodeContent)} />
                                                                        </div>
                                                                        <div className="manual-address">
                                                                            <p>{esimDetails?.qrCodeContent}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={24} className="default-col">
                                                                        <div className="title-manual-item">
                                                                            <h2>{t('home.header.activation_code')}</h2>
                                                                            <Button className="button-copy" icon={<CopyOutlined />} size="small" onClick={() => handleCopyToClipboard(esimDetails?.iccid)} />
                                                                        </div>
                                                                        <p>{esimDetails?.iccid}</p>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <h2>{t('home.header.apn')}</h2>
                                                                        <p>{esimDetails?.apn}</p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={24} className="action-box">
                                                        <Row gutter={[16, 16]}>
                                                            <Col span={19} >
                                                                <Button className="default-button button-install-now" type="primary" onClick={() => handleInstalleSIM(esimDetails?.qrCodeContent)} >
                                                                    {t('global.button.install_esim')}
                                                                </Button>
                                                            </Col>
                                                            <Col span={5}>
                                                                <Button className="default-button button-request-other" type="primary" onClick={clearInfo}>
                                                                {t('global.button.clear')}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Row className="processing-general">
                                                    <Col span={24} className="content-processing">
                                                        <div className="loader"></div>
                                                        <h1>{t('home.header.proccessing_qr_code')}</h1>
                                                        <p>
                                                            {t('home.header.processing_qr_code_description')}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )
                                        ) : (
                                            <Row className="content-get-plan">
                                                {formGetQr.map((item, index) => (
                                                    <Col span={item.span} key={index} className="wrapper-box-input">
                                                        <label>
                                                            {item.label} {item.required && <span className="required">*</span>}
                                                        </label>
                                                        <Input
                                                            type={item.type}
                                                            name={item.name}
                                                            placeholder={item.placeholder}
                                                            size="large"
                                                            className={
                                                                isFormSubmitted
                                                                    ? item.name === 'tranx_number'
                                                                        ? !transactionNumber
                                                                            ? 'class-required input-box'
                                                                            : 'input-box'
                                                                        : !paymentId
                                                                        ? 'class-required input-box'
                                                                        : 'input-box'
                                                                    : 'input-box'
                                                            }
                                                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                                            onClear={() => handleInputChange(item.name, '')}
                                                            allowClear
                                                        />
                                                    </Col>
                                                ))}
                                                <Col span={24} className="action-box">
                                                    <Button className="button-submit" type="primary" onClick={submitGetQr}>
                                                        {t('global.button.submit')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                )}
                            </Drawer>
                            <Dropdown
                                menu={{
                                    items: itemLanguage,
                                    onClick,
                                }}
                            >
                                <Button onClick={(e) => e.preventDefault()} className="button-menu">
                                    <GlobalOutlined /> {t(`global.${language}`)}
                                </Button>
                            </Dropdown>
                        </Col>
                    )
                }
                <Drawer 
                    title={t('home.header.settings')} 
                    placement="bottom"
                    height="100%"
                    className="drawer-menu"
                    closable={false}
                    onClose={closeDrawer} 
                    open={isOpen}
                    getContainer={() => document.getElementById('header-style-custom')}
                >
                    <Row className="content">
                        <Col span={24} className="menu-item cursor">
                            <div className="menu-item-has-child cursor" onClick={toggleLanguageChange}>
                                <p><GlobalOutlined className="icon" /> {t('global.language')}</p>
                                <h2>{t(`global.${language}`)}</h2>
                            </div>
                            <Drawer
                                title={t('home.header.select_lenguage')} 
                                placement="bottom"
                                className="drawer-language"
                                onClose={toggleLanguageChange} 
                                open={isLanguageChanged}
                                getContainer={() => document.getElementById('header-style-custom')}
                                height={250}
                            >
                                <Row>
                                    {itemLanguage.map((item, index) => (
                                        <Col
                                            span={24}
                                            className="list-language"
                                            key={index}
                                            onClick={() => onClick({ key: item.key })}
                                        >
                                            <p>{item.label}</p>
                                            <div className="check-box">
                                                <div className={item.key === language ? 'black-dot' : 'white-dot'} />
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Drawer>
                        </Col>
                        <Col span={24} className="menu-item cursor">
                            <div className="menu-item-has-child cursor" onClick={handleEsimInfo}>
                                <p><QrcodeOutlined className="icon" /> {t('home.header.get_your_esim')}</p>
                                {isQrGenerated ? <UpOutlined /> : <DownOutlined />}
                            </div>
                            <Drawer
                                title={t('home.header.get_your_esim')} 
                                placement="bottom"
                                className="drawer-esim"
                                onClose={handleEsimInfo} 
                                open={isQrGenerated}
                                getContainer={() => document.getElementById('header-style-custom')}
                                height="100%"
                            >
                                {isQrGenerated && (
                                    <div className="wrapper-get-plan">
                                        {paymentStatus ? (
                                            <div>
                                                {isQrValidRef.current ? (
                                                    <Row className="content-qr">
                                                        <Col span={24} >
                                                            <Row className="qr-box">
                                                                <Col span={24}>
                                                                    <h1> 1: {t('home.header.qr_install')}</h1>
                                                                    <p>{t('home.header.description_qr_install')}</p>
                                                                </Col>
                                                                <Col span={24} id="myqrcode">
                                                                    <QRCode
                                                                        type="svg"
                                                                        errorLevel="H"
                                                                        value={esimDetails?.qrCodeContent}
                                                                        icon="/favicon.ico"
                                                                        size={250}
                                                                    />
                                                                    <Button className="button-save" onClick={handleSaveQRCode}>
                                                                        {t('global.button.save_qr')}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <Row className="manual-box">
                                                                <Col span={24}>
                                                                    <h1>2: {t('home.header.data_manual_install')}</h1>
                                                                    <p>{t('home.header.description_manual_install')}</p>
                                                                </Col>
                                                                <Col span={24} className="content-manual">
                                                                    <Row gutter={[16, 16]}>
                                                                        <Col span={24} className="default-col">
                                                                            <div className="title-manual-item">
                                                                                <h2>{t('home.header.sm_dp')}</h2>
                                                                                <Button className="button-copy" icon={<CopyOutlined />} size="small" onClick={() => handleCopyToClipboard(esimDetails?.qrCodeContent)} />
                                                                            </div>
                                                                            <div className="manual-address">
                                                                                <p>{esimDetails?.qrCodeContent}</p>
                                                                            </div>
                                                                        </Col>
                                                                        <Col span={24} className="default-col">
                                                                            <div className="title-manual-item">
                                                                                <h2>{t('home.header.activation_code')}</h2>
                                                                                <Button className="button-copy" icon={<CopyOutlined />} size="small" onClick={() => handleCopyToClipboard(esimDetails?.iccid)} />
                                                                            </div>
                                                                            <p>{esimDetails?.iccid}</p>
                                                                        </Col>
                                                                        <Col span={24}>
                                                                            <h2>{t('home.header.apn')}</h2>
                                                                            <p>{esimDetails?.apn}</p>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col span={24} className="action-box">
                                                            <Row gutter={[16, 16]}>
                                                                <Col span={19} >
                                                                    <Button className="default-button button-install-now" type="primary" onClick={() => handleInstalleSIM(esimDetails?.qrCodeContent)} >
                                                                        {t('global.button.install_esim')}
                                                                    </Button>
                                                                </Col>
                                                                <Col span={5}>
                                                                    <Button className="default-button button-request-other" type="primary" onClick={clearInfo}>
                                                                    {t('global.button.clear')}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row className="processing-general">
                                                        <Col span={24} className="content-processing">
                                                            <div className="loader"></div>
                                                            <h1>{t('home.header.proccessing_qr_code')}</h1>
                                                            <p>
                                                                {t('home.header.processing_qr_code_description')}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </div>
                                        ) : (
                                            <Row className="content-get-plan">
                                                {formGetQr.map((item, index) => (
                                                    <Col span={item.span} key={index} className="wrapper-box-input">
                                                        <label>
                                                            {item.label} {item.required && <span className="required">*</span>}
                                                        </label>
                                                        <Input
                                                            type={item.type}
                                                            name={item.name}
                                                            placeholder={item.placeholder}
                                                            size="large"
                                                            className={
                                                                isFormSubmitted
                                                                    ? item.name === 'tranx_number'
                                                                        ? !transactionNumber
                                                                            ? 'class-required input-box'
                                                                            : 'input-box'
                                                                        : !paymentId
                                                                        ? 'class-required input-box'
                                                                        : 'input-box'
                                                                    : 'input-box'
                                                            }
                                                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                                            onClear={() => handleInputChange(item.name, '')}
                                                            allowClear
                                                        />
                                                    </Col>
                                                ))}
                                                <Col span={24} className="action-box">
                                                    <Button className="button-submit" type="primary" onClick={submitGetQr}>
                                                        {t('global.button.submit')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                )}
                            </Drawer>
                        </Col>
                        <Col span={24} className="menu-item cursor">
                            <div
                                className="menu-item-has-child cursor"
                                onClick={handleDrawerInstallGuide}
                            >
                                <p><ReadOutlined className="icon" /> {t('home.header.installation_guide')}</p>
                                {isInstructionOpen ? <UpOutlined /> : <DownOutlined />}
                            </div>
                        </Col>
                        <Col span={24} className="menu-item cursor">
                            <div
                                className="menu-item-has-child cursor"
                                onClick={() =>
                                    window.open(
                                        'https://www.youtube.com/watch?v=yikN-UgysnU&pp=ygUQYWJhIHBheW1lbnQgYmlsbA%3D%3D',
                                        '_blank'
                                    )
                                }
                            >
                                <p><PlayCircleOutlined className="icon" /> {t('home.header.how_to_do_payment')}</p>
                                <RightOutlined />
                            </div>
                        </Col>
                    </Row>
                    <Button className="button-close-drawer" onClick={closeDrawer}>
                        {t('global.button.close')}
                    </Button>
                </Drawer>
                <Drawer
                    title={t('home.header.installation_guide')}
                    placement={isMobileView ? 'bottom' : 'right'}
                    className="drawer-esim"
                    onClose={() => setIsInstructionOpen(false)}
                    open={isInstructionOpen}
                    getContainer={() => document.getElementById('header-style-custom')}
                    height="100%"
                    width={isMobileView ? '100%' : 550}
                >
                    <InstructionEsim warning={false} />
                </Drawer>
            </Row>
        </HeaderWrapper>
    );
}

export default Header;
