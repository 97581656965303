import { React } from 'react';
import { useTranslation } from "react-i18next";

import { Row, Col } from 'antd';
import { MailFilled, PhoneFilled } from '@ant-design/icons';


import { FooterWrapper } from '../ComponentStyle';

const Footer = () => {
    const { t } = useTranslation();
    
    const contactInfo = [
        {
            icon: <PhoneFilled style={{color: '#ff2600', fontSize: '16px'}}/>,
            content: process.env.REACT_APP_COMPANY_PHONE,
        },{
            icon: <MailFilled style={{color: '#ff2600', fontSize: '16px'}}/>,
            content: process.env.REACT_APP_COMPANY_EMAIL,
        }
    ];

    return (
        <FooterWrapper>
            <Row className='footer-container'>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className='about-us-info'>
                    <h1>{t('home.footer.about_us')}</h1>
                    <p>
                        {t('home.footer.about_us_description')}
                    </p>
                    <Row className='contact-info'>
                        {contactInfo.map((item, index) => (
                            <Col span={24} key={index} className={ index + 1 !== contactInfo.length ? 'add-margin contact-item' : 'contact-item'} >
                                {item.icon} <p>{item.content}</p>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className='copyright'>
                    <img src="/images/esim-logo-white.png" alt="logo-esim" />
                    <p>Copyright © 2024, SpaciaNet Japan Inc., Ltd. All rights reserved.</p>
                </Col>
            </Row>
        </FooterWrapper>
    );
}

export default Footer;
